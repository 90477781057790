export enum EnvironmentType {
    PROD = "prod",
    PREPROD = "preprod",
    TESTING = "testing",
    DEV = "dev",
}

export interface IEnvironment {
    avatarAssetsPath: string;
    apiEndPoint: string;
    apiLogin: string;
    apiToken: string;
    baseUrl: string;
    biApiuri: string;
    biApiToken: string;
    clientBaseUrl: string;
    countryAssetsPath: string;
    credentials: {
        username: string;
        password: string;
    };
    imageDimensions: {
        width: number;
        height: number;
    };
    insurancesApiEndPoint: string;
    insurancesApiToken: string;
    mapServerUrl: string;
    moustacheApiUrl: string;
    moustacheAssetsBasePath: string;
    moustacheAssetsOptimizedPath: string;
    production: boolean;
    screensaverTimeTripMinute: number;
    type: EnvironmentType;
    quotationApiUrl: string;
    newQuotationApiUrl: string;
    searchDebounceTime: number;
    strapiApiUrl: string;
    strapiApiToken: string;
    strapiPublicUrl: string;
    streamingVideoPath: string;
    inatictivityTime: number;
    mediaAssetsPath: string;
    mediaAssetsPathOptimized: string;
    siteAssetsUrl: string;
}
